import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <section>
          <span className="App-inline">
            Contact
          </span>
          <a
            className="App-link"
            href="mailto:me@enjoy.cooking"
            target="_blank"
            rel="noopener noreferrer"
          >
            me@enjoy.cooking
          </a>
        </section>
        <p>
          for more info
        </p>
      </header>
    </div>
  );
}

export default App;
